import React from 'react';
import { useState } from 'react';
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import {
  RecoilRoot,
  atom,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from 'recoil';
import {
  BrowserRouter as Router,
  Switch as RouterSwitch,
  Route,
  Link as RouterLink,
  useLocation,
  useHistory
} from "react-router-dom";
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import DownloadIcon from '@mui/icons-material/Download';
import ListItemText from '@mui/material/ListItemText';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ClearIcon from '@mui/icons-material/Clear';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import InputLabel from '@mui/material/InputLabel';
import PeopleIcon from '@mui/icons-material/People';
import BusinessIcon from '@mui/icons-material/Business';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import AppsIcon from '@mui/icons-material/Apps';
import HomeIcon from '@mui/icons-material/Home';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import TextField from '@mui/material/TextField';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import { Alert, Autocomplete, Collapse } from '@mui/material';
import Fab from '@mui/material/Fab';
import FilterListIcon from '@mui/icons-material/FilterList';
import Tooltip from '@mui/material/Tooltip';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import KeyOffIcon from '@mui/icons-material/KeyOff';
import Badge from '@mui/material/Badge';
import { isPast, format, add} from 'date-fns';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Checkbox from '@mui/material/Checkbox';
import MailIcon from '@mui/icons-material/Mail';
import WorkIcon from '@mui/icons-material/Work';
import Snackbar from '@mui/material/Snackbar';
import Chip from '@mui/material/Chip';
import ButtonBase from '@mui/material/ButtonBase'

import { LoginView } from 'auth/components/login';
import { AuthProvider, AuthContent, PrivateRoute } from 'auth/components/auth-provider';
import { useAuthActions } from 'auth/actions';

import { authAtom } from 'auth/state';
import { appsAtom } from 'app/state';
import { useAppActions } from 'app/actions';
import { AppsView } from 'app/components';
import { LoadingSpinner } from 'common/components';
import { StdAppBar, StdFooter } from 'common/components';

import { useAxios } from 'common/use-axios';

import { stdTheme, loginTheme } from 'common/themes';

import { LoginAppBar } from 'auth/components/login-toolbar';
import { LoginFooter } from 'auth/components/login-footer';

import { HomeView } from 'home/components/home-view';

const usersState = atom({key: 'users', default: null});
const orgsState = atom({key: 'orgs', default: null});
const selectedUser = atom({key: 'selectedUser', default: null});
const selectedOrg = atom({key: 'selectedOrg', default: null});

function Feedback(props) {
    const {open, setOpen, message, level} = props;
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };
    const action = (
        <React.Fragment>
            <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );
    return (
        <div>
            {level ? <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                <Alert onClose={handleClose} severity={level}>
                    {message}
                </Alert>
            </Snackbar>: <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                message={message} action={action} />}
        </div>
    );
}

const ListField = (props) => {
    const {containingObj, setContainingObj, field } = props;
    return <Box sx={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}} >
        {(containingObj[field] || []).map((entry, i) => (
        (entry && entry.editable) ? <TextField key={i} variant="standard" size="small" type="text"
                autoFocus sx={{m: 0.5}}
                value={entry.value} onChange={e => {
                    var newEntries = [...containingObj[field]];
                    newEntries[i] = {...newEntries[i], value: e.target.value};
                    var newObj = {...containingObj};
                    newObj[field] = newEntries;
                    setContainingObj(newObj);
                }} onBlur={e => {
                    var newEntries = [...containingObj[field]];
                    if (e.target.value && containingObj[field].indexOf(e.target.value) == -1) {
                        newEntries[i] = e.target.value;
                    } else {
                        newEntries.splice(i, 1);
                    }
                    newEntries.sort();
                    var newObj = {...containingObj};
                    newObj[field] = newEntries;
                    setContainingObj(newObj);
                }} onKeyPress={(e) => {
                    if (e.key == 'Enter') {
                        var newEntries = [...containingObj[field]];
                        if (e.target.value && containingObj[field].indexOf(e.target.value) == -1) {
                            newEntries[i] = e.target.value;
                        } else {
                            newEntries.splice(i, 1);
                        }
                        newEntries.sort();
                        var newObj = {...containingObj};
                        newObj[field] = newEntries;
                        setContainingObj(newObj);
                    }
                }} onKeyDown={(e) => {
                    if (e.key == 'Escape') {
                        e.preventDefault();
                        var newEntries = [...containingObj[field]];
                        newEntries.splice(i, 1);
                        newEntries.sort();
                        var newObj = {...containingObj};
                        newObj[field] = newEntries;
                        setContainingObj(newObj);
                    }
                }}
         />: <Chip key={i} label={entry} sx={{m: 0.5}} onDelete={(e) => {
            var newEntries = [...containingObj[field]];
            newEntries.splice(i, 1);
            var newObj = {...containingObj};
            newObj[field] = newEntries;
            setContainingObj(newObj);
        }} />
    ))}
    {(containingObj[field] || []).filter(entry => entry.editable).length ? null: <IconButton onClick={() => {
        setContainingObj((app) => {
            const newEntries = [
                ...(app[field] || []),
                {value: '', editable: true}
            ];
            var newObj = {...app};
            newObj[field] = newEntries;
            return newObj;
        })}}><AddIcon /></IconButton>}
    </Box>
}

const ListChoiceField = (props) => {
    const {containingObj, setContainingObj, field, choices } = props;
    const usedChoices = (containingObj[field] || []).map((x) => x.id);
    const remainingChoices = choices.filter((x) => usedChoices.indexOf(x.id) == -1);
    return <Box sx={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}} >
        {(containingObj[field] || []).map((entry, i) => (
        (entry && entry.editable) ? <Autocomplete key={i} size="small"
            isOptionEqualToValue={(a, b) => a.id == b.id}
            disablePortal
            options={remainingChoices}
            sx={{ width: 200 }}
            value={entry.label} onChange={(e, newVal) => {
                var newEntries = [...containingObj[field]];
                newEntries[i] = newVal;
                var newObj = {...containingObj};
                newObj[field] = newEntries;
                setContainingObj(newObj);
            }}
            renderInput={(params) => <TextField {...params} label="Client"
                variant="standard" autoFocus size="small" type="text" sx={{m: 0.5}}
                onBlur={e => {
                    var newEntries = [...containingObj[field]];
                    newEntries.splice(i, 1);
                    newEntries.sort();
                    var newObj = {...containingObj};
                    newObj[field] = newEntries;
                    setContainingObj(newObj);
                }} onKeyDown={(e) => {
                    if (e.key == 'Escape') {
                        var newEntries = [...containingObj[field]];
                        newEntries.splice(i, 1);
                        newEntries.sort();
                        var newObj = {...containingObj};
                        newObj[field] = newEntries;
                        setContainingObj(newObj);
                    }
                }}
         />}/>: <Chip key={i} label={entry.label || entry} sx={{m: 0.5}} onDelete={(e) => {
            var newEntries = [...containingObj[field]];
            newEntries.splice(i, 1);
            var newObj = {...containingObj};
            newObj[field] = newEntries;
            setContainingObj(newObj);
        }} />
    ))}
    {(!remainingChoices.length || (containingObj[field] || []).filter(entry => entry.editable).length)
        ? null: <IconButton onClick={() => {
        setContainingObj((app) => {
            const newEntries = [
                ...(app[field] || []),
                {value: '', editable: true}
            ];
            var newObj = {...app};
            newObj[field] = newEntries;
            return newObj;
        })}}><AddIcon /></IconButton>}
    </Box>
}

const CustomFields = (props) => {
    const {containingObj, setContainingObj, field} = props;
    return <Grid container padding={1} spacing={1}>
        {containingObj && containingObj[field] ? containingObj[field].map((f, i) => (
            <React.Fragment key={'customFields-' + i}>
            <Grid item xs={12} md={4}>
                <TextField size="small" label="Name" type="text" fullWidth value={f.name} required
                    onChange={e => setContainingObj((org) => {
                        var newOrg = {...org};
                        newOrg[field] = [...newOrg[field]];
                        newOrg[field][i] = {...newOrg[field][i], name: e.target.value};
                        return newOrg;
                    })}
                />
            </Grid>
            <Grid item xs={12} md={7}>
                <TextField size="small" label="Value" type="text" fullWidth value={f.value} required
                    onChange={e => setContainingObj((org) => {
                        var newOrg = {...org};
                        newOrg[field] = [...newOrg[field]];
                        newOrg[field][i] = {...newOrg[field][i], value: e.target.value};
                        return newOrg;
                    })}
                />
            </Grid>
            <Grid item xs={12} md={1}>
            <IconButton size="small" aria-label="close" color="inherit" onClick={() => {
                setContainingObj((org) => {
                    var newOrg = {...org};
                    newOrg[field] = [...newOrg[field]];
                    newOrg[field].splice(i, 1);
                    return newOrg;
                });
            }}>
                <CloseIcon fontSize="small" />
            </IconButton>
            </Grid>
            </React.Fragment>
        )): null}
        <Grid item xs={12} md={6}>
        <IconButton onClick={() => {
            setContainingObj((org) => {
                var newObj = {...org};
                newObj[field] = [...(org[field] || []), {name: '', value: ''}]
                return newObj;
            });
        }}><AddIcon /></IconButton>
        </Grid>
    </Grid>
}


const EditMenu = (props) => {
    const {
        openDialog, anchorEl, setAnchorEl, setError, selectedThing, thingsState, kind,
        additionalMenuItem, deleteConfirm, setDeleteConfirm, includeDelete, showFeedback,
        includeAdditionalMenuItem, onEditHook, setFilters
    } = props;
    const axios = useAxios({});
    const [things, setThings] = useRecoilState(thingsState);
    const setCurrentThing = useSetRecoilState(selectedThing);
    const thingAttr = 'data-' + kind + 'id';

    const editThing = (e) => {
        e.preventDefault();
        var thingId = anchorEl.getAttribute(thingAttr);
        things.map((thing) => {
            if (thing.id == thingId) {
                var newThing = {...thing};
                if (onEditHook) {
                    onEditHook(newThing);
                }
                setCurrentThing(newThing);
            }
        });
        setAnchorEl(null);
        setError('');
        openDialog();
    };
    const handleDelete = (e) => {
        e.preventDefault();
        if (deleteConfirm) {
            var thingId = anchorEl.getAttribute(thingAttr);
            var name = '';
            for (const i in things) {
                if (things[i].id == thingId) {
                    name = things[i].name;
                    break;
                }
            }
            axios.delete('/api/v1/' + kind + '/' + anchorEl.getAttribute(thingAttr))
            .then((data) => {
                var message = 'Deleted ' + kind + ': ' + name;
                setDeleteConfirm(false);
                setAnchorEl(null);
                if (kind == 'user') {
                    setFilters(prev=>{return {...prev}});
                } else {
                    setThings(null);
                }

                showFeedback(message, 'success');
            }).catch((error) => {
                var message = 'Deleting ' + kind + ': ' + name + ' failed: ' + error.response.data;
                console.error('Delete Error for ', kind, ':', error.response.data);
                setDeleteConfirm(false);
                setAnchorEl(null);
                showFeedback(message, 'error');
            });
        } else {
            setDeleteConfirm(true);
        }
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return <Menu id={kind + '-edit-menu'} anchorEl={anchorEl} keepMounted
        open={Boolean(anchorEl)} onClose={handleClose}>
        <AuthContent permission={`UPDATE_${kind.toUpperCase()}`}>
            <MenuItem onClick={editThing}>
                <EditIcon fontSize="small" />&nbsp;Edit...
            </MenuItem>
        </AuthContent>
        {includeAdditionalMenuItem ? additionalMenuItem: null}
        <AuthContent permission={`DELETE_${kind.toUpperCase()}`}>
            {includeDelete ? <MenuItem onClick={handleDelete} >
                <DeleteIcon fontSize="small" />&nbsp;
                {deleteConfirm ? 'Really Delete?': 'Delete'}
            </MenuItem>: null}
        </AuthContent>
    </Menu>
}

const UserDialog = (props) => {
    const {userDialogOpen, closeUserDialog, error, setError, showFeedback , apps, orgs, setFilters} = props;
    const axios = useAxios({});
    const [currentUser, setCurrentUser] = useRecoilState(selectedUser);
    const [currentApp, setCurrentApp] = React.useState(null);
    const [currentAppOrg,setCurrentAppOrg] = React.useState(null);
    const [currentOrg, setCurrentOrg] = React.useState(null);

    const handleAppChange = (e) => {
        if(e.currentTarget.value != currentApp){
        setCurrentApp(e.currentTarget.value);
        setCurrentAppOrg(null);
        }
    }

    const handleAppOrgChange = (event,value) => {
        setCurrentAppOrg(value && value.id?value.id:null)

    }
    React.useEffect(()=>{
        setCurrentAppOrg(null)
        setCurrentOrg(null)
        setCurrentApp(null)
    }, [userDialogOpen])

    var appLookup = {};
    (apps || []).map((app) => {
        appLookup[app.id] = app
    })

    var orgLookup = {};
    (orgs || []).map((org)=>{
        orgLookup[org.id] = org
    })

    /* FIXME There is a whole load of historical commented out code later, handling org change and permissions.
       It used the following ...

    const deleteOrgPermission = (event)=>{
        setCurrentOrg(null)
        setCurrentUser((u)=>{
            var new_org_permissions = [...u.org_permissions]
            new_org_permissions = new_org_permissions.filter(perm=>{
                return perm.org_id != event.currentTarget.value
            }
            )
            // console.log(new_org_permissions)
            return {...u,org_permissions:new_org_permissions}

        })
    }

    const toggleOrgPermission = (event)=>{
        setCurrentUser((u)=>{
            var mutableUser=JSON.parse(JSON.stringify(u));
            if((mutableUser.org_permissions|| []).length ){
            var index_of_permission = mutableUser.org_permissions.findIndex(perm=>{
                return perm.org_id == currentOrg
            });
            if (index_of_permission>-1){
            var newPermissions = [...mutableUser.org_permissions[index_of_permission].permissions]
            if (newPermissions.indexOf(event.target.name)>-1){
                newPermissions = newPermissions.filter((p)=>(p != event.target.name));
            }else {
                newPermissions.push(event.target.name);
                newPermissions.sort();
            }
            mutableUser.org_permissions[index_of_permission].permissions = newPermissions
            if (!newPermissions.length){
                mutableUser.org_permissions.splice(index_of_permission,1)
            }
            return mutableUser
        }else{
            mutableUser.org_permissions.push({org_id:currentOrg,
                permissions:[event.target.name]})
            return mutableUser

        }
        }else{
            mutableUser.org_permissions = [{org_id:currentOrg,
                permissions:[event.target.name]}]
            return mutableUser
        }})
    }
    */

    const deleteAppPermission = (event)=>{
        setCurrentAppOrg(null)
        setCurrentApp(null)
        setCurrentUser((u)=>{
            var new_app_permissions = [...u.app_permissions]
            new_app_permissions = new_app_permissions.filter(perm=>{
                return perm.org_id != event.currentTarget.value || perm.app_id != event.currentTarget.name         
            })
            return {...u,app_permissions:new_app_permissions}
        })
    }

    const toggleAppPermission=(event)=>{
            setCurrentUser((u)=>{
                var mutableUser=JSON.parse(JSON.stringify(u)); // creating a mutable user object
                if((mutableUser.app_permissions || []).length ){ // if the field exists
                var index_of_permission = mutableUser.app_permissions.findIndex(perm=>{
                    return perm.org_id == currentAppOrg && perm.app_id == currentApp                    
                });
                if (index_of_permission>-1){ // if the permission for current app and org exists
                var newPermissions = [...mutableUser.app_permissions[index_of_permission].permissions]// getting permission array
                if (newPermissions.indexOf(event.target.name)>-1){ // if current permission present
                    newPermissions = newPermissions.filter((p)=>(p != event.target.name)); // removing permission
                }else {
                    newPermissions.push(event.target.name); // adding new permission
                    newPermissions.sort();
                }
                mutableUser.app_permissions[index_of_permission].permissions = newPermissions
                if (!newPermissions.length){
                        mutableUser.app_permissions.splice(index_of_permission,1)
                }
                return mutableUser
            }else{
                mutableUser.app_permissions.push({org_id:currentAppOrg,app_id:currentApp,
                    permissions:[event.target.name]})
                return mutableUser 
            }
            }else{
                mutableUser.app_permissions = [{org_id:currentAppOrg,
                    app_id:currentApp,
                    permissions:[event.target.name]}]
                return mutableUser
            }})
        }

        
    
    const checkBox = (event) => {
        setCurrentUser((u) => {
            var newPermissions = [...u.permissions];
            if (newPermissions.indexOf(event.target.name) > -1) {
                newPermissions = newPermissions.filter((p) => (p != event.target.name));
            } else {
                newPermissions.push(event.target.name);
                newPermissions.sort();
            }
            return {...u, permissions: newPermissions}
        })
    };


    const clientOrSupplier=()=>{
        var org = orgs.filter(org=>(org.id == currentAppOrg))[0];
        var value = []
        if ((org.app_ids ||[]).indexOf(currentApp)>-1){
            value.push(true)
        }else{
            value.push(false)
        }

        if ((org.supplier_to||[]).map(supp=>{
            return supp.app_id == currentApp            
        }).indexOf(true)> -1){
            value.push(true)
        }else{
            value.push(false)
        }
        return value // [client,supplier]
    }


    const submitUser = (e) => {
        e.preventDefault();
        var data = {
            name: currentUser.name,
            email: currentUser.email,
            permissions: currentUser.permissions,
            org_permissions: currentUser.org_permissions,
            app_permissions: currentUser.app_permissions
        };
        var custom = {};
        if (currentUser.customFields) {
            currentUser.customFields.map((c) => {
                if (c.name && c.value) {
                    custom[c.name] = c.value;
                };
            });
        }
        if (Object.keys(custom).length) {
            data.custom = custom;
        }
        var method;
        var url;
        if (currentUser.id) {
            method = 'put';
            url = '/api/v1/user/' + currentUser.id;
        } else {
            method = 'post';
            url = '/api/v1/user';
        }
        axios({url: url, method: method, data: data})
        .then(response => {
            const message = (method == 'put' ? 'Updated': 'Created') + ' user: ' + data.name;
            closeUserDialog();
            setFilters(prev=>{return {...prev}});
            showFeedback(message, 'success');
        })
        .catch((error) => {
            console.error('User Update Error:', error.response.data);
            setError(error.response.data);
        });
    };
    return <Dialog open={userDialogOpen} onClose={closeUserDialog}>
        <DialogTitle id="edit-user-dialog-title" sx={{
            backgroundColor: stdTheme.palette.primary.main, color: '#fff'
        }}>
            <Typography variant="body2" sx={{
                textTransform: 'uppercase', fontWeight: 500
                }}>{currentUser && currentUser.id ? 'Edit User': 'Create User'}</Typography>
            <IconButton aria-label="close" sx={{
                    position: 'absolute', right: stdTheme.spacing(1), top: '2px',
                    color: stdTheme.palette.grey[500]
               }} onClick={closeUserDialog} size="large">
                <CloseIcon />
            </IconButton>
        </DialogTitle>
        <DialogContent>
        <Grid container spacing={2} pt={2}>
            <Grid item xs={12}>
                <TextField autoFocus size="small" id="userName" label="Name" type="text"
                    value={currentUser ? currentUser.name: ''} onChange={e => setCurrentUser({
                        ...currentUser, name: e.target.value
                    })} fullWidth required />
            </Grid>

            <Grid item xs={12}>
                <TextField size="small" id="userEmail" label="Email" type="text"
                    value={currentUser ? currentUser.email: ''} onChange={e => setCurrentUser({
                        ...currentUser, email: e.target.value
                    })} fullWidth required />
            </Grid>

            <Grid item xs={12}>
                <Typography variant="normal">Custom Fields</Typography>
                <CustomFields containingObj={currentUser} setContainingObj={setCurrentUser} field="customFields" />
            </Grid>

            <Grid item xs={12}>
                <FormControl sx={{ m: 1 }} component="fieldset" variant="standard">
                    <FormLabel component="legend">Premoni Permissions</FormLabel>
                    <Divider /> 
                    <FormGroup>
                        <FormControlLabel control={
                            <Checkbox checked={currentUser && currentUser.permissions.indexOf('admin') > -1}
                                onChange={checkBox} name="admin" />
                        } label="Admin" />
                    </FormGroup>
                </FormControl>
            </Grid>

            {/* <Grid item xs={12}>
                <FormLabel sx={{ m: 1 }} component="legend">Org Permissions</FormLabel>
                <Divider />
            </Grid>
            {currentUser && currentUser.org_permissions && currentUser.org_permissions.length>0 && currentUser.org_permissions.map((perm)=>{
            return(<Grid item xs={12} key={perm.org_id}>
                <Grid container spacing={2} direction ='row'>
                    <Grid item xs={10}>
                        <ButtonBase onClick={e=>setCurrentOrg(e.currentTarget.value)} value={perm.org_id}>
                            <Grid container  spacing={1} direction='row'>
                                <Grid item xs={8} md={6}>
                                    <TextField id="organisation" size="small" label="Org" type="text"
                                        value={orgLookup[perm.org_id].name} onChange={() => {}} fullWidth disabled />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField id="Permission" size="small" label="Permission" type="text"
                                         value={perm.permissions[0]} onChange={() => {}} fullWidth  disabled />
                                </Grid>
                            </Grid>
                        </ButtonBase>
                    </Grid>
                    <Grid item xs={2} style={{display:'inline'}}>
                        <ButtonBase onClick={deleteOrgPermission} value ={perm.org_id}><ClearIcon /></ButtonBase>
                    </Grid>
                    </Grid>
                </Grid>)
            })} */}

            {/* <Grid item xs={12}>
                <Grid container justifyContent="space-around" alignItems="center" direction='row'>
                    <Divider />
                    <Grid item xs={12}>
                        <FormControl sx={{ m: 1 }} component="fieldset" variant="standard">
                                <FormGroup>
                        <Autocomplete  size="small"
                            isOptionEqualToValue={(a, b) => a.id == b.id}
                            disablePortal={true}
                            getOptionLabel={x => x.label || x.name}
                            key={currentOrg}
                            value={orgLookup[currentOrg]? orgLookup[currentOrg]:null}
                            id="organisation"
                            options={
                                (orgs||[]).map((org,i) => {
                                        return {label: org.name, id:org.id}
                                    })
                            }
                            sx={{ width: 300 }}
                            onChange={(e,value)=>handleOrgChange(e,value)}
                            renderInput={(params) => <TextField {...params} label="Select Org" />}
                            />
                            </FormGroup>
                            </FormControl>
                    </Grid>


                    {currentOrg && <Grid item xs={12}>
                    <FormControlLabel control={
                                    <Checkbox sx={{ m: 1 }} key={currentOrg} checked={currentUser && ((currentUser.org_permissions || []).map((x)=> {
                                        return x.org_id ==currentOrg && x.permissions.indexOf('admin')>-1                                        
                                    }).indexOf(true)>-1)}
                                        onChange={toggleOrgPermission} name="admin" />
                                } label="Org Admin" />
                                </Grid>}
                </Grid>
            </Grid> */}
            <Grid item xs={12}>
                <FormLabel sx={{ m: 1 }} component="legend">App Permissions</FormLabel>
                <Divider />
            </Grid>
            {currentUser && currentUser.app_permissions && currentUser.app_permissions.length>0 && currentUser.app_permissions.map((perm)=>{
            return(
            <Grid item xs={12} key={perm.app_id+ perm.org_id}>
                <Grid container spacing={2} direction ='row'>
                    <Grid item xs={11}>
                        <ButtonBase onClick={e=>{setCurrentApp(e.currentTarget.name);setCurrentAppOrg(e.currentTarget.value)}} value={perm.org_id} name={perm.app_id}>
                            <Grid container  spacing={1} direction='row'>
                                <Grid item xs={4} >
                                    <TextField id="app" size="small" label="App" type="text"
                                        value={appLookup[perm.app_id].name} onChange={() => {}} fullWidth disabled />
                                </Grid>
                                <Grid item xs={4} >
                                    <TextField id="organisation" size="small" label="organisation" type="text"
                                        value={orgLookup[perm.org_id].name} onChange={() => {}} fullWidth disabled />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField id="Permission" size="small" label="Permission" type="text"
                                        value={perm.permissions} onChange={() => {}} fullWidth  disabled />
                                </Grid>
                            </Grid>
                        </ButtonBase>
                    </Grid>
                    <Grid item xs={1}>
                    <ButtonBase onClick={deleteAppPermission} value ={perm.org_id} name={perm.app_id}><ClearIcon /></ButtonBase>
                    </Grid>
                </Grid>
            </Grid>)
            })}
            <Grid item xs={12}>
                <Grid container  direction='row'>
                {(apps || []).filter((app)=>!(app.is_legacy||false)).map((app,i)=>{
                    return(<Grid item  key = {i}>
                        <Card  sx={{ margin: '0.2rem'}} style={{backgroundColor: currentApp == app.id ? stdTheme.palette.primary.light:"white"}}>
                            <ButtonBase  onClick={handleAppChange} value={app.id}>
                        <CardContent>
                            <Typography sx={{ fontSize: 14 }} color={"text.primary"} gutterBottom>
                            {app.name}
                            </Typography>
                        </CardContent>
                        </ButtonBase >
                        </Card>
                        </Grid>
                    )
                })
                }
                </Grid>
            </Grid>

            <Grid item xs={12}>
                {(currentApp && (orgs.filter(org=> {
                        if ((org.app_ids || []).indexOf(currentApp)>-1){
                            return true
                        }else if((org.supplier_to || []).map(supp=>{
                            if (supp.app_id == currentApp){
                                return true
                            }}).indexOf(true)>-1){
                                return true
                            }
                        }) || []).length) ? // add supplier orgs here 
                <Autocomplete  size="small"
                    isOptionEqualToValue={(a, b) => a.id == b.id}
                    getOptionLabel={x => x.label || x.name}
                    disablePortal={true}
                    value = {orgLookup[currentAppOrg]? orgLookup[currentAppOrg]:null}
                    key={currentApp}
                    id="App organisation"
                    options={
                        (orgs.filter(org=> {
                            if ((org.app_ids || []).indexOf(currentApp)>-1){
                                return true
                            }else if((org.supplier_to || []).map(supp=>{
                                if (supp.app_id == currentApp){
                                    return true
                                }}).indexOf(true)>-1){
                                    return true
                                }
                            }) || []).map((org,i) => {
                                return {label: org.name, id:org.id}
                            })
                    }
                    sx={{ width: 300 }}
                    onChange={(e,value)=>handleAppOrgChange(e,value)}
                    renderInput={(params) => <TextField {...params} label="Select Org" />}
                    />
                :(currentApp && <FormControl fullWidth>
                <InputLabel>No Organisation available for {apps.filter((app)=> app.id == currentApp)[0].name}</InputLabel>
                </FormControl>)}
            </Grid>

            <Grid item xs={6} >                                                               
                {(currentAppOrg && currentApp && clientOrSupplier()[0]) && <FormLabel component="legend">Client Permissions</FormLabel>} 
                <FormControl sx={{ m: 1 }} component="fieldset" variant="standard">
                <FormGroup>
                {(currentApp && currentAppOrg && clientOrSupplier()[0])&& apps.filter((app)=> app.id == currentApp)[0].client_permissions.map((clientPerm)=>{ 
                return <FormControlLabel key={clientPerm} control={
                    <Checkbox checked={currentUser && currentAppOrg &&((currentUser.app_permissions || []).map((perm)=> {
                        return perm.app_id == currentApp && perm.org_id ==currentAppOrg && perm.permissions.indexOf(clientPerm)>-1                        
                    }).indexOf(true)>-1)}
                        onChange={toggleAppPermission} name={clientPerm} />
                } label={clientPerm} /> 
                            
                })}  
                </FormGroup>
                </FormControl>                     
            </Grid> 
            
            <Grid item xs={(currentApp && currentAppOrg && clientOrSupplier()[0])?6:12} >
                {(currentAppOrg && currentApp && clientOrSupplier()[1]) && <FormLabel component="legend">Supplier Permissions</FormLabel>}
                <FormControl sx={{ m: 1 }} component="fieldset" variant="standard">
                <FormGroup>
                {(currentAppOrg && currentApp && clientOrSupplier()[1]) &&
                (apps.filter((app)=> app.id == currentApp)[0].supplier_permissions || []).map((suppPerm)=>{return<FormControlLabel key = {suppPerm} control={
                                <Checkbox checked={currentUser && currentAppOrg &&((currentUser.app_permissions || []).map((perm)=> {
                                    return perm.app_id == currentApp && perm.org_id ==currentAppOrg && perm.permissions.indexOf(suppPerm)>-1
                                }).indexOf(true)>-1)}
                                    onChange={toggleAppPermission} name={suppPerm} />
                            } label={suppPerm} />
                })}
                </FormGroup>
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                {error ? <Alert severity="error">{error}</Alert>: null}
            </Grid>
        </Grid>
        </DialogContent>
        <DialogActions sx={{ backgroundColor: stdTheme.palette.primary.main }}>
            {/* <Button onClick={ResetUser} >Reset</Button> */}
            <Button onClick={submitUser} >Save</Button>
            <Button onClick={closeUserDialog} >Cancel</Button>
        </DialogActions>
    </Dialog>
}

function ReportFilters(props){
    const {filters, setFilters, filterFields, downloadFunction} = props
    const axios = useAxios({});
    const [filterOpen, setFilterOpen] = React.useState(false)
    let history = useHistory();
    let location = useLocation();

    const getParam = (key)=>{
        var param = new URLSearchParams(history.location.search)
        return param.get(key)
      }

    const setParams = (search)=>{
        var param = new URLSearchParams(history.location.search)
        var changes = false
        for(const [key,value] of Object.entries(search)){
            if(value != getParam(key)){
            changes = true
            param.set(key,value)
        }
        }
        if(changes){
        history.push({
            pathname: history.location.pathname,
            search: param.toString()
        })}
      }
    
    const replaceParams = (search)=>{
    var param = new URLSearchParams(history.location.search)
    var changes = false
    for(const [key,value] of Object.entries(search)){
        if(value != getParam(key)){
            changes = true
            param.set(key,value)
        }
    }

    if(changes){
    history.replace({
        pathname: history.location.pathname,
        search: param.toString()
    })}
    }

    const removeParams=(keys)=>{
        var param = new URLSearchParams(history.location.search)
        var keys_present = false
        for(const key of keys){
            if(param.get(key)){
                keys_present =true
                param.delete(key)
        }
        }
        if(keys_present){
        history.push({
            pathname: history.location.pathname,
            search: param.toString()
        })}
    }

    React.useEffect(()=>{
    let filter = {}
    for( const field of filterFields){
        if(getParam(field.name)){
            filter[field.name] = getParam(field.name)
        }
    }
    if(Object.keys(filter).length>0){
        setFilters(filter)
    }else{
        if(Object.keys(filters).length>0){
            setFilters({});
        }
    }
    }, [location.search])

    const filterChange = (event,value,filter_name)=>{
        var remove = []
        const copy = {...filters}
        if (!value){
            delete copy[filter_name]
            remove.push(filter_name)
        }else{
            copy[filter_name] = value;
        }
        removeParams(remove);
        setParams(copy);
    }

    return <React.Fragment>
    <IconButton key={filters} 
                        aria-label="Filter-options"  onClick={()=>{setFilterOpen(!filterOpen)}}>
    <Badge color="primary" badgeContent={Object.keys(filters).length}>
        <FilterListIcon />
    </Badge>
    </IconButton>
        <Collapse in={filterOpen} orientation='vertical' unmountOnExit style={{width:'100%'}}>
            <Grid item key={filters} xs={12} md ={12} 
            style={{
            paddingLeft:'5px',
            margin:'5px',
            paddingBottom:'10px'
        }}>
                <Grid container spacing={2} >
                    {filterFields.map(field=>(<Grid item xs={4}
                    md={4} key = {field.name}>
                            <Autocomplete id={field.name} size={"small"} sx={{minWidth:'200px'}} name={field.name} options={[...field.options, '']}
                                value={filters[field.name] || ''} onChange={(event,value)=>filterChange(event,value,field.name)}
                                getOptionLabel={(x)=>{return field.lookup[x]?field.lookup[x] :x}}
                                renderOption={(props, option, state) => (
                                    <Typography {...props} style={{color: stdTheme.palette.primary.main,fontSize: "0.8rem" }}>{field.lookup[option]?field.lookup[option] :option}</Typography>
                                    )}
                                renderInput={(params) => (
                                <TextField  {...params} name={field.name}  label={field.description} color='primary'
                                inputProps={{...params.inputProps, "data-lpignore":"true", sx:{color:stdTheme.palette.primary.main,fontSize:'0.8rem'}}}
                                InputLabelProps={{sx:{color:stdTheme.palette.primary.main,fontSize:'0.8rem'}}}
                                />)}
                            />
                        </Grid>))}
                        {downloadFunction && <Grid item xs={4}>
                        <Tooltip title="Download User Report">
                        <Fab color="primary" sx = {{height:'36px', width: '36px'}} onClick={downloadFunction}>
                            <DownloadIcon />
                        </Fab>
                        </Tooltip>
                        </Grid>}
                </Grid>
            </Grid>
        </Collapse>
    </React.Fragment>
}


const UsersView = () => {
    const axios = useAxios({});
    const [users, setUsers] = useRecoilState(usersState);
    const apps  = useRecoilValue(appsAtom);
    const info = useRecoilValue(authAtom);
    const [orgs,setOrgs] = useRecoilState(orgsState);
    const [userDialogOpen, setUserDialogOpen] = React.useState(false);
    const setCurrentUser = useSetRecoilState(selectedUser);
    const [error, setError] = React.useState(null);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [deleteConfirm, setDeleteConfirm] = React.useState(false);
    const [includeDelete, setIncludeDelete] = React.useState(false);
    const [includeAdditionalMenuItem, setIncludeAdditionalMenuItem] = React.useState(false);
    const [usersExpanded, setUsersExpanded] = React.useState({});
    const [snackbarOpen, setSnackbarOpen] = React.useState(false);
    const [snackbarMessage, setSnackbarMessage] = React.useState('');
    const [snackbarLevel, setSnackbarLevel] = React.useState(null);
    const [filters, setFilters] = React.useState({});
    const [filterFields, setFilterFields] = React.useState(null);
    const appActions = useAppActions();
    

    const showFeedback = (message, level) => {
        setSnackbarOpen(true);
        setSnackbarMessage(message);
        setSnackbarLevel(level);
    }

    React.useEffect(() => {
        const getUsers = (url)=>{
            axios.get(url)
            .then(
                (data) => {
                    setUsers(data.data.data);
                })
                .catch((error) => {
                    console.warn('oh dear - no users retrieved', error);
                }
            );
        }

        if (users == null) {
            getUsers('api/v1/user');
        }else{
            let url = 'api/v1/user'
            let params = new URLSearchParams()
            for( const [key, value] of Object.entries(filters)){
                if(value){
                    params.set(key, value)
                }
            }
            if(params.toString()){
                url = url +'?'+ params.toString()
                getUsers(url)
            }else{
                getUsers('api/v1/user');
            }
        }
    }, [filters]);

    React.useEffect(()=>{
        if(!filterFields){
            axios.get('/api/v1/get_filters/user').then((response)=>{
                if(response.data){
                    setFilterFields(response.data.data)
                }
            }).catch((error)=>{
                console.warn('getting filter failed')
            })
        }
    }, [filterFields])



    React.useEffect(() => {
        if (orgs == null) {
            axios.get('/api/v1/org')
            .then(
                (data) => {
                    setOrgs(data.data.data);
                })
                .catch((error) => {
                    console.warn('oh dear - no orgs retrieved', error);
                }
            );
        }
    }, [orgs]);

    var org_lookup = {}
    if(orgs){
        orgs.forEach(org=>{org_lookup[org.id]= org})
    }

    React.useEffect(() => {
        (async () => { appActions.fetchAll() })();
        // eslint-disable-next-line
    }, []);  // empty dependencies, so single shot on component creation.

    function createUserReport(users){
        let csv_text = 'Name,Email,Organisation,'
        let csv_apps = [...apps].filter(a=>!a['is_legacy']).sort(((a,b)=> a.name.localeCompare(b.name)))
        csv_text += csv_apps.map((a)=>a.name).join(',') + '\n'
        users.forEach(user => {
            if(user.app_permissions && user.app_permissions.length>0){
            let user_orgs = '"' + [... new Set(user.app_permissions.map(perm=>org_lookup[perm.org_id].name))].join(', ')+'"'
            let user_apps = user.app_permissions.map(perm=>perm.app_id)
            let login_info = csv_apps.map((app)=>{
                if(user_apps.includes(app.id)){
                    return  user.registered ? (user.app_last_logins[app.id] ?
                    format(
                        user.app_last_logins[app.id].$date, 'd MMM yyyy'
                    ):'Never Logged in'): 'Never Registered'
                }else{
                    return ''
                }
            })
            csv_text +=  [user.name, user.email, user_orgs, ...login_info].join(',') + '\n'
        }else{
            csv_text +=[user.name, user.email, '', ...csv_apps.map(app=>'')].join(',') + '\n'
        }
        });
        return csv_text
    }

    const downloadReport=()=>{
        const csv_content = createUserReport(users)
        const blob = new Blob([csv_content], {type: 'text/csv;charset=utf-8;'});
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.setAttribute('href',url);
        link.setAttribute('download', 'User-Report.csv');
        document.body.appendChild(link);
        link.click();
        URL.revokeObjectURL(url);
    }

    const openUserDialog = () => {
        setUserDialogOpen(true);
    };
    const closeUserDialog = () => {
        setUserDialogOpen(false);
    };

    const openUserEditMenu = (event) => {
        setAnchorEl(event.currentTarget);
        setDeleteConfirm(false);
        const userId = event.currentTarget.getAttribute('data-userid');
        const allowDelete = !event.currentTarget.getAttribute('data-retain');
        setIncludeDelete(allowDelete);
        setIncludeAdditionalMenuItem(userId != info.id);
    };
    const createUser = (e) => {
        e.preventDefault();
        setCurrentUser({name: '', email: '', permissions: []});
        setError('');
        openUserDialog();
    }

    const expandUser = (user_id) => {
        setUsersExpanded((expanded) => {
            const newExpanded = {...expanded};
            newExpanded[user_id] = expanded[user_id] ? false: true;
            return newExpanded;
        });
    }
    
    const userEditHook = (user) => {
        if (user.custom) {
            user.customFields = Object.keys(user.custom).map((k) => {
                return {name: k, value: user.custom[k]}
            });
        }
    }


    const activityMessage = (user) => {
        const event = user.last_registration_event;
        const message = (user.registered && !user.confirmed) ?
            'Email sent to confirm change of email address': event.message;
        return message + ' on ' + format(event.time.$date, 'd MMM yyyy HH:mm')
    }

    const resetPassword = (e) => {
        e.preventDefault();
        var userId = anchorEl.getAttribute('data-userid');
        var userName = '';
        for (const i in users) {
            if (users[i].id == userId) {
                userName = users[i].name;
                break;
            }
        }
        axios.post('/api/v1/resend/' + userId)
            .then((data) => {
                var message = 'Sent password reset email for: ' + userName;
                setFilters(prev=>{return {...prev}});
                showFeedback(message, 'success');
            }).catch((error) => {
                console.error('Resend error for user:', userId, error.response.data);
                var message = 'Resetting password for user: ' + userName + ' failed: ' + error.response.data;
                showFeedback(message, 'error');
            });
        setAnchorEl(null);
    }

    return <Container disableGutters ={true}>
        {!users || !apps || !orgs ? null : <React.Fragment>
            <UserDialog userDialogOpen={userDialogOpen} closeUserDialog={closeUserDialog}
                error={error} setError={setError} showFeedback={showFeedback} apps = {apps} orgs = {orgs} setFilters={setFilters}/>
            <EditMenu openDialog={openUserDialog} anchorEl={anchorEl} setAnchorEl={setAnchorEl}
                setError={setError} selectedThing={selectedUser} thingsState={usersState} kind="user"
                deleteConfirm={deleteConfirm} setDeleteConfirm={setDeleteConfirm}
                includeDelete={includeDelete} showFeedback={showFeedback}
                includeAdditionalMenuItem={includeAdditionalMenuItem}
                additionalMenuItem={
                    <MenuItem onClick={resetPassword}>
                        <MailIcon fontSize="small" />&nbsp;Resend Registration Email
                    </MenuItem>
                } onEditHook={userEditHook} setFilters={setFilters}/>
            <Feedback open={snackbarOpen} setOpen={setSnackbarOpen} message={snackbarMessage}
                level={snackbarLevel}/>
            <Box  sx={{width: 'inherit', maxWidth: 'inherit', zIndex: 1}}>
            <Grid container>
                <Grid item xs={12} md={12} key={0} sx={{backgroundColor: 'rgba(255, 255, 255, 1)',position: '-webkit-sticky',
                             position: 'sticky', top:'65px', zIndex:'1'}}>
                    {filterFields && <ReportFilters  filters={filters} setFilters={setFilters} filterFields={filterFields} downloadFunction={downloadReport} />}
                </Grid>
            <Grid item xs={12} md={12} sx={{zIndex:'0'}}>
            <List sx={{paddingTop: '0px'}}>{users.map((user) => {
                const last_login = Math.max(...Object.values(user.app_last_logins).map(x => x ? x.$date: 0));
                const message = activityMessage(user);
                const inactive = (last_login > 0 && isPast(add(last_login, {months: 3})))

                var user_apps = [...user.apps];
                const starting = user_apps.length;
                (user.app_permissions || []).map((perm) => {
                    if (user_apps.indexOf(perm.app_id) == -1) {
                        user_apps.push(perm.app_id);
                    }
                });

                return <React.Fragment key={user.id}>
                <ListItem sx={{paddingTop: 0, paddingBottom: 0}}>
                    <ListItemText
                        primary={<>
                            <span>{user.name} {
                                user.permissions.indexOf('admin') > -1 ? <Tooltip title="Administrator">
                                <IconButton>
                                    <SupervisorAccountIcon />
                                </IconButton>
                                </Tooltip>: null}
                            </span>
                            { !user.is_native_auth &&
                                <span>
                                    <Tooltip title="External authentication"><IconButton><KeyOffIcon/></IconButton></Tooltip>
                                </span>
                            }
                        </>}
                        secondary={user.email} sx={{ cursor: 'pointer' }}
                        onClick={() => {expandUser(user.id);}} />
                    <ListItemSecondaryAction>
                        {!user.confirmed || !user.registered ?
                            <Tooltip title={message}>
                                <IconButton onClick={() => {expandUser(user.id);}}>
                                    <HourglassTopIcon />
                                </IconButton>
                            </Tooltip>: inactive ?
                            <Tooltip title={'Last login: ' + format(last_login, 'd MMM yyyy HH:mm')}>
                                <IconButton onClick={() => {expandUser(user.id);}}>
                                    <AccessTimeIcon />
                                </IconButton>
                            </Tooltip>: null}
                        {user_apps.length ?
                            <IconButton onClick={() => {expandUser(user.id);}}>
                                <Badge color="primary" badgeContent={user_apps.length}>
                                    <AppsIcon />
                                </Badge>
                            </IconButton>: null}
                        <IconButton onClick={openUserEditMenu} data-userid={user.id}
                            data-retain={user_apps.length || user.id == info.id ? 'retain': null}
                            edge="end" size="large">
                            <MoreHorizIcon />
                        </IconButton>
                    </ListItemSecondaryAction>
                </ListItem>
                {apps && usersExpanded[user.id] ? <Box px={2} textAlign="center">
                    {user_apps.length ? <Grid container spacing={2}>
                    {user_apps.map((app_id) => {
                        var name = '';
                        apps.map((app) => {
                            if (app.id == app_id) {name = app.name}
                        })
                        return <Grid item xs={6} md={3} key={user.id + ':' + app_id}>
                            <Card variant="outlined">
                                <CardContent sx={{padding: '5px'}}>{name}</CardContent>
                                <CardContent sx={{padding: '5px'}}>
                                    <Typography variant="caption">
                                        {user.app_last_logins[app_id] ?
                                            'Last login: ' + format(
                                                user.app_last_logins[app_id].$date, 'd MMM yyyy'
                                            ): 'Never logged in'}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    })}
                </Grid>: null}
                    <Typography variant="caption">{message}</Typography>
                </Box>: null}
                <Divider /></React.Fragment>
            })}</List>
            </Grid>
            </Grid>
            </Box>
            <Grid container justifyContent="center" spacing={2}>
                <Grid item>
                    <Tooltip title="Create User">
                        <Fab color="primary" onClick={createUser}>
                            <AddIcon />
                        </Fab>
                    </Tooltip>
                </Grid>
            </Grid>
        </React.Fragment>}
    </Container>
}

const OrgDialog = (props) => {
    const {orgDialogOpen, closeOrgDialog, error, setError, showFeedback, apps} = props;
    const axios = useAxios({});
    const [currentOrg, setCurrentOrg] = useRecoilState(selectedOrg);
    const [orgs, setOrgs] = useRecoilState(orgsState);

    const submitOrg = (e) => {
        e.preventDefault();
        var data = {
            name: currentOrg.name,
        };
        ['app_ids', 'upload_bucket', 'upload_region'].map(field => {
            if (currentOrg[field]) {
                data[field] = currentOrg[field];
            }
        });
        var supplier_to = [];
        Object.keys(currentOrg).filter(k => k.startsWith('app_clients_')).map(k => {
            if (currentOrg[k].length) {
                supplier_to.push({
                    app_id: k.substring(12),
                    client_ids: currentOrg[k].map(x => x.id)
                })
            }
        });
        if (supplier_to.length) {
            data.supplier_to = supplier_to;
        }
        var custom = {};
        if (currentOrg.customFields) {
            currentOrg.customFields.map((c) => {
                if (c.name && c.value) {
                    custom[c.name] = c.value;
                };
            });
        }
        if (Object.keys(custom).length) {
            data.custom = custom;
        }

        var method;
        var url;
        if (currentOrg.id) {
            method = 'put';
            url = '/api/v1/org/' + currentOrg.id;
        } else {
            method = 'post';
            url = '/api/v1/org';
        }
        axios({url: url, method: method, data: data})
        .then(response => {
            const message = (method == 'put' ? 'Updated': 'Created') + ' org: ' + data.name;
            closeOrgDialog();
            setOrgs(null);
            showFeedback(message, 'success');
        })
        .catch((error) => {
            console.error('Org Update Error:', error.response.data);
            setError(error.response.data);
        });
    };
    const [state, setState] = React.useState({
        client: false,
        supplier: false
    });
    const handleChange = (event) => {
        setState({
            ...state,
            [event.target.name]: event.target.checked,
        });
    };
    var appClients = {};
    var orgLookup = {};
    orgs.map((org) => {
        (org.app_ids || []).map((app) => {
            if (!appClients[app]) {
                appClients[app] = {}
            }
            appClients[app][org.id] = true;
        });
        orgLookup[org.id] = org;
    });


    return <Dialog open={orgDialogOpen} onClose={closeOrgDialog} disableEscapeKeyDown >
        <DialogTitle id="edit-org-dialog-title" sx={{
            backgroundColor: stdTheme.palette.primary.main, color: '#fff'
        }}>
            <Typography variant="body2" sx={{
                textTransform: 'uppercase', fontWeight: 500
                }}>{currentOrg && currentOrg.id ? 'Edit Org': 'Create Org'}</Typography>
            <IconButton aria-label="close" sx={{
                    position: 'absolute', right: stdTheme.spacing(1), top: '2px',
                    color: stdTheme.palette.grey[500]
               }} onClick={closeOrgDialog} size="large">
                <CloseIcon />
            </IconButton>
        </DialogTitle>
        <DialogContent>
        <Grid container spacing={2} pt={2}>
            <Grid item xs={12}>
                <TextField autoFocus size="small" id="orgName" label="Name" type="text"
                    value={currentOrg ? currentOrg.name: ''} onChange={e => setCurrentOrg({
                        ...currentOrg, name: e.target.value
                    })} fullWidth required />
            </Grid>
            <Grid item xs={12}>
                <TextField size="small" id="orgBucket" label="AWS Bucket" type="text"
                    value={currentOrg ? currentOrg.upload_bucket: ''} onChange={e => setCurrentOrg({
                        ...currentOrg, upload_bucket: e.target.value
                    })} fullWidth />
            </Grid>
            <Grid item xs={12}>
                <TextField size="small" id="orgBucket" label="AWS Region" type="text"
                    value={currentOrg ? currentOrg.upload_region: ''} onChange={e => setCurrentOrg({
                        ...currentOrg, upload_region: e.target.value
                    })} fullWidth />
            </Grid>
            <Grid item xs={12}>
                <Typography variant="normal">Custom Fields</Typography>
                <CustomFields containingObj={currentOrg} setContainingObj={setCurrentOrg} field="customFields" />
            </Grid>
            {currentOrg && apps && apps.filter((app)=>!(app.is_legacy||false)).map(app => {

                const client_ids = (currentOrg.supplier_to || []).filter(ac => ac == app.id).map((ac) => {
                    return ac.client_ids;
                });
                const choices = Object.keys(appClients[app.id] || {}).filter((c) => (c != currentOrg.id))
                    .map((c) => {
                        return {
                            'id': c,
                            'label': orgLookup[c].name
                        };
                    })
                return <Grid item xs={12} key={app.id}>
                    <Typography variant="h6">{app.name}</Typography>
                    <FormGroup>
                        <FormControlLabel control={
                            <Switch checked={(currentOrg.app_ids || []).indexOf(app.id) != -1}
                                onChange={(e) => {
                                    var newAppIds = [...(currentOrg.app_ids || [])];
                                    var updated = false;
                                    if (!e.target.checked) {
                                        const appIndex = newAppIds.indexOf(app.id);
                                        if (appIndex != -1) {
                                            newAppIds.splice(appIndex, 1);
                                            updated = true;
                                        }
                                    } else if (newAppIds.indexOf(app.id) == -1) {
                                        updated = true;
                                        newAppIds.push(app.id);
                                    }
                                    if (updated) {
                                        setCurrentOrg({...currentOrg, app_ids: newAppIds});
                                    }
                            }} name="client" />
                        } label="Client" />
                    {choices.length ? <Typography variant="normal">Supplier To Clients</Typography>: null}
                    {choices.length ? <ListChoiceField containingObj={currentOrg}
                        setContainingObj={setCurrentOrg} field={"app_clients_" + app.id}
                        choices={choices} />: null}
                    </FormGroup>

                </Grid>
            })}
            <Grid item xs={12}>
                {error ? <Alert severity="error">{error}</Alert>: null}
            </Grid>
        </Grid>
        </DialogContent>
        <DialogActions sx={{ backgroundColor: stdTheme.palette.primary.main }}>
            <Button onClick={submitOrg} >Save</Button>
            <Button onClick={closeOrgDialog} >Cancel</Button>
        </DialogActions>
    </Dialog>
}

const OrgsView = () => {
    const axios = useAxios({});
    const [orgs, setOrgs] = useRecoilState(orgsState);
    const [apps, setApps] = useRecoilState(appsAtom);
    const [orgDialogOpen, setOrgDialogOpen] = React.useState(false);
    const setCurrentOrg = useSetRecoilState(selectedOrg);
    const [error, setError] = React.useState(null);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [deleteConfirm, setDeleteConfirm] = React.useState(false);
    const [includeDelete, setIncludeDelete] = React.useState(false);
    const [orgsExpanded, setOrgsExpanded] = React.useState({});
    const [snackbarOpen, setSnackbarOpen] = React.useState(false);
    const [snackbarMessage, setSnackbarMessage] = React.useState('');
    const [snackbarLevel, setSnackbarLevel] = React.useState(null);
    const showFeedback = (message, level) => {
        setSnackbarOpen(true);
        setSnackbarMessage(message);
        setSnackbarLevel(level);
    }
    const appActions = useAppActions();

    React.useEffect(() => {
        if (orgs == null) {
            axios.get('/api/v1/org')
            .then(
                (data) => {
                    setOrgs(data.data.data);
                },
                (error) => {
                    console.warn('oh dear - no orgs retrieved', error);
                }
            );
        }
    }, [orgs]);

    React.useEffect(() => {
        (async () => { appActions.fetchAll() })();
        // eslint-disable-next-line
    }, []);  // empty dependencies, so single shot on component creation.

    const openOrgDialog = () => {
        setOrgDialogOpen(true);
    };
    const closeOrgDialog = () => {
        setOrgDialogOpen(false);
    };
    const openOrgEditMenu = (event) => {
        setAnchorEl(event.currentTarget);
        setDeleteConfirm(false);
        const orgId = event.currentTarget.getAttribute('data-orgid');
        const allowDelete = !event.currentTarget.getAttribute('data-retain');
        setIncludeDelete(allowDelete);
    };
    const createOrg = (e) => {
        e.preventDefault();
        setCurrentOrg({name: ''});
        setError('');
        openOrgDialog();
    }

    const expandOrg = (org_id) => {
        setOrgsExpanded((expanded) => {
            const newExpanded = {...expanded};
            newExpanded[org_id] = expanded[org_id] ? false: true;
            return newExpanded;
        });
    }

    const orgEditHook = (org) => {
        var orgLookup = {};
        orgs.map(x => {
            orgLookup[x.id] = x.name;
        });
        (org.supplier_to || []).map(ac => {
            org['app_clients_' + ac['app_id']] = ac['client_ids'].map(c_id => ({
                id: c_id,
                label: orgLookup[c_id]
            }));
        });
        if (org.custom) {
            org.customFields = Object.keys(org.custom).map((k) => {
                return {name: k, value: org.custom[k]}
            });
        }
    }

    return <Container fixed>
        {!orgs ? null : <React.Fragment>
            <OrgDialog orgDialogOpen={orgDialogOpen} closeOrgDialog={closeOrgDialog} apps={apps}
                error={error} setError={setError} showFeedback={showFeedback}/>
            <EditMenu openDialog={openOrgDialog} anchorEl={anchorEl} setAnchorEl={setAnchorEl}
                setError={setError} selectedThing={selectedOrg} thingsState={orgsState} kind="org"
                deleteConfirm={deleteConfirm} setDeleteConfirm={setDeleteConfirm}
                includeDelete={includeDelete} showFeedback={showFeedback}
                onEditHook={orgEditHook} />
            <Feedback open={snackbarOpen} setOpen={setSnackbarOpen} message={snackbarMessage}
                level={snackbarLevel}/>
            <List>{orgs.map((org) => {

                var org_apps = {};
                var isClient = false;
                var isSupplier = false;
                (org.app_ids || []).map((app_id) => {
                    org_apps[app_id] = org_apps[app_id] || {};
                    org_apps[app_id].client = true;
                    isClient = true;
                });
                (org.supplier_to || []).map((app_clients) => {
                    org_apps[app_clients.app_id] = org_apps[app_clients.app_id] || {};
                    org_apps[app_clients.app_id].suppliers = app_clients.client_ids.length;
                    if (app_clients.client_ids.length) {
                        isSupplier = true;
                    }
                });
                const app_count = Object.keys(org_apps).length;
                var appLookup = {};
                (apps || []).map((app) => {
                    appLookup[app.id] = app;
                });

                return <React.Fragment key={org.id}>
                <ListItem sx={{paddingTop: 0, paddingBottom: 0}}>
                    <ListItemText primary={<span>{org.name}</span>} secondary={
                            isClient ? isSupplier ? 'Client and Supplier': 'Client': isSupplier ? 'Supplier': ''
                        } sx={{ cursor: 'pointer' }}
                        onClick={() => {expandOrg(org.id);}} />
                    <ListItemSecondaryAction>
                        {app_count ?
                            <IconButton onClick={() => {expandOrg(org.id);}}>
                                <Badge color="primary" badgeContent={app_count}>
                                    <AppsIcon />
                                </Badge>
                            </IconButton>: null}
                        <IconButton onClick={openOrgEditMenu} data-orgid={org.id}
                            data-retain={org.apps && org.apps.length ? 'retain': null}
                            edge="end" size="large">
                            <MoreHorizIcon />
                        </IconButton>
                    </ListItemSecondaryAction>
                </ListItem>
                {apps && orgsExpanded[org.id] ? <Box px={2} textAlign="center">
                    {app_count ? <Grid container spacing={2}>
                    {Object.keys(org_apps)
                        .sort((a, b) => (appLookup[a].name > appLookup[b].name ? 1: -1))
                        .map((app_id) => {
                        return <Grid item xs={6} md={3} key={org.id + ':' + app_id}>
                            <Card variant="outlined">
                                <CardContent sx={{padding: '5px'}}>{appLookup[app_id].name}</CardContent>
                                <CardContent sx={{padding: '5px'}}>
                                    <Typography variant="caption">
                                        {org_apps[app_id].client ? 'Client': ''}
                                        {(org_apps[app_id].client && org_apps[app_id].suppliers) ? ' | ': ''}
                                        {org_apps[app_id].suppliers ? (
                                            'Supplier to ' + org_apps[app_id].suppliers +
                                             ' client' +
                                            ((org_apps[app_id].suppliers > 1) ? 's': ''))
                                        : ''}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    })}

                </Grid>: null}
                    <Typography variant="caption">
                        {'Last Modified: ' + format(org._meta.update_time.$date, 'd MMM yyyy HH:mm')}
                    </Typography>
                </Box>: null}
                <Divider /></React.Fragment>
            })}</List>
            <Grid container justifyContent="center" spacing={2}>
                <Grid item>
                    <Tooltip title="Create Org">
                        <Fab color="primary" onClick={createOrg}>
                            <AddIcon />
                        </Fab>
                    </Tooltip>
                </Grid>
            </Grid>
        </React.Fragment>}
    </Container>
}

function MyApp() {
    const registerConfirmPaths = ["/register/:code", "/confirm/:code"];
    const userActions = useAuthActions();
    const location = useLocation();
    const auth = useRecoilValue(authAtom);

    const [menuOpen, setMenuOpen] = useState(false);
    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setMenuOpen(open);
    };

    const [to, setTo] = useState(false);

    const renderLink = React.useMemo(
        () => React.forwardRef((itemProps, ref) => <RouterLink to={to} ref={ref} {...itemProps} />),
        [to],
    );

    const onLogout = async (e) => {
        userActions.logout()
        return false
    }

    const navList = () => (
        <div className="widthAuto" role="presentation" onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)}>
            <List>
                <ListItemButton key="Home" component={renderLink} to="/">
                    <ListItemIcon><HomeIcon /></ListItemIcon>
                    <ListItemText primary="Home" />
                </ListItemButton>
                <AuthContent permission="READ_APP">
                    <ListItemButton key="Apps" component={renderLink} to="/apps">
                        <ListItemIcon><AppsIcon /></ListItemIcon>
                        <ListItemText primary="Apps" />
                    </ListItemButton>
                </AuthContent>
                <AuthContent permission="READ_USER">
                    <ListItemButton key="Users" component={renderLink} to="/users">
                        <ListItemIcon><PeopleIcon /></ListItemIcon>
                        <ListItemText primary="Users" />
                    </ListItemButton>
                </AuthContent>
                <AuthContent permission="READ_ORG">
                    <ListItemButton key="Orgs" component={renderLink} to="/orgs">
                        <ListItemIcon><WorkIcon /></ListItemIcon>
                        <ListItemText primary="Orgs" />
                    </ListItemButton>
                </AuthContent>
                <AuthContent permission="READ_SITE">
                    <ListItemButton key="Sites" component={renderLink} to="/sites">
                        <ListItemIcon><BusinessIcon /></ListItemIcon>
                        <ListItemText primary="Sites" />
                    </ListItemButton>
                </AuthContent>
            </List>
        </div>
    );

    var theme = stdTheme
    if (!auth || location.pathname.startsWith('/register/') || location.pathname.startsWith('/confirm/')) {
        theme = loginTheme
    }

    return (
        <React.Fragment>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <LoadingSpinner>
                    <AuthProvider>
                        {theme === loginTheme ?
                            <LoginAppBar/>
                         :
                            <>
                                <React.Fragment key="menu">
                                    <Drawer open={menuOpen} onClose={toggleDrawer(false)}>{navList()}</Drawer>
                                </React.Fragment>
                                <StdAppBar toggleDrawer={toggleDrawer} onLogout={onLogout}/>
                            </>
                        }

                        <Box m={1} pt={8} component="div">
                            <RouterSwitch>
                                <Route path={registerConfirmPaths} component={LoginView} />
                                <Route exact path="/" component={HomeView} />
                                <PrivateRoute permission="READ_APP"  path="/apps"  component={AppsView} />
                                <PrivateRoute permission="READ_USER" path="/users" component={UsersView} />
                                <PrivateRoute permission="READ_ORG"  path="/orgs"  component={OrgsView} />
                                <PrivateRoute permission="READ_SITE" path="/sites" />
                            </RouterSwitch>
                        </Box>

                        {theme === loginTheme ? <LoginFooter/> : <StdFooter/>}

                    </AuthProvider>
                </LoadingSpinner>
            </ThemeProvider>
        </React.Fragment>
    );
}

function App() {
    return <StyledEngineProvider injectFirst>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <RecoilRoot>
                <Router>
                    <MyApp />
                </Router>
            </RecoilRoot>
        </LocalizationProvider>
    </StyledEngineProvider>
}

export default App;
export { EditMenu, Feedback, ListField };
